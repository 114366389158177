<template>

    <footer>
        <div class="container">
            <div class="block50">
                <p>Information supplémentaires</p>
                <p>
                    Ce site ne conserve aucune donnée, il se base sur l'enregistrement de votre navigateur.
                    Par conséquent si vous changez de navigateur, vos informations seront perdues.
                </p>
                <p>
                    Veillez à toujours utiliser le même poste pour reprendre où vous en étiez.
                </p>
                <p>Bon à savoir :</p>
                <p>
                    Pour la MDPH ou la MDA, il existe une antenne dans chaque département. Pour connaitre ses coordonnées, tu peux demander conseil à une
                    assistante sociale dans ton établissement scolaire ou à ta mairie.
                </p>
                <a href="https://www.lespepsra.org/"><img src="../assets/img/logo-pepsra.png" alt=""></a>
            </div>
            <div class="block25">
                <p>Plan du site</p>
                <ul>
                    <li><router-link to="/" aria-label="">Accueil</router-link></li>
                    <li><router-link to="/settings" aria-label="">Réglages</router-link></li>
                    <li><router-link to="/login" aria-label="">Login</router-link></li>
                    <li><router-link to="/categories" aria-label="">Catégories</router-link></li>
                    <ul>
                        <li><router-link to="/dream" aria-label="">{{ this.requestQ.dream.label }}</router-link></li>
                        <li><router-link to="/selfdone" aria-label="">{{ this.requestQ.selfdone.label }}</router-link></li>
                        <li><router-link to="/social" aria-label="">{{ this.requestQ.social.label }}</router-link></li>
                        <li><router-link to="/school" aria-label="">{{ this.requestQ.school.label }}</router-link></li>
                        <li><router-link to="/health" aria-label="">{{ this.requestQ.health.label }}</router-link></li>
                        <li v-if="this.user.age >= 16"><router-link to="/right" aria-label="">{{ this.requestQ.right.label }}</router-link></li>
                        <li v-if="this.user.enterprise === true"><router-link to="/work" aria-label="">{{ this.requestQ.work.label }}</router-link></li>

                    </ul>
                    <li><router-link to="/results" aria-label="">Résultats</router-link></li>
                </ul>
            </div>
            <div class="block25">
                <p>Lien utiles</p>
                <p><a href="https://www.lespepsra.org/" target="_blank">PEP Sud-Rhône-Alpes</a></p>
                <p><a href="https://www.planning-familial.org/fr" target="_blank">Planning familliale</a></p>
                <p><a href="https://www.capemploi.info/" target="_blank">Cap Emploi</a></p>
                <p><a href="https://www.agefiph.fr/" target="_blank">Agefiph</a></p>
                <p><a href="https://www.mission-locale.fr/" target="_blank">Mission locale</a></p>
                <p><a href="https://www.sncf.com/fr/offres-voyageurs/voyager-en-toute-situation/accessibilite/services-assistance/acces-plus" target="_blank">SNCF Access Plus</a></p>
                <p><a href="https://www.handisport.org/" target="_blank">Handisport</a></p>
            </div>
        </div>
        <div class="container">
            <div class="block100">
                <p>PEP Sud-Rhône-Alpes @All rights reserved 2022</p>
            </div>
        </div>
    </footer>

</template>

<script>
    export default {
        name: "Footer",
    }
</script>

<style scoped>
    footer {
        background-color: var(--dc);
        padding: 60px 0;
    }

    footer ul {
        display: block
    }

    footer ul:nth-child(2) {
        text-align: left;
    }

    footer ul li {
        display: block;
    }

    footer ul li > a {
        background-color: transparent;
        box-shadow: none;
        font-size: 0.7em;
        padding: 5px;
        border-color: var(--dc);
    }

    footer ul ul {
        margin-left: 20px;
    }

    footer p {
        color: var(--wc);
        text-align: justify;
    }

    footer a {
        color: var(--wc);
    }

    footer .container:first-child {
        padding-bottom: 30px;
    }

    footer .block25 p:first-child,
    footer .block50 p:first-child {
        text-transform: uppercase;
        font-size: .75em;
    }

    footer .container:nth-of-type(1) > .block25:nth-of-type(2) {
        padding: 0 40px;
    }

    footer .block100 {
        border-top: 1px solid #424242;
    }

    footer .block100 p {
        font-size: .7em;
        color: #414141;
        text-align: center;
    }

    footer img {
        display: block;
    }
    @media (max-width: 45em /*720px*/) {
        footer .container:nth-of-type(1) > .block25:nth-of-type(2) {
            padding: 0;
        }
    }
</style>