<template>
    <div class="container-fluid" id="results">
        <div class="center-content">
            <div class="container">
                <div class="block75">
                    <img src="../assets/img/focus_menu.svg" alt="Logo menu">
                    <h1>Résultats</h1>
                    <p>
                        Retrouves ici les résultats de ta progression, tu peux aussi enregistrer un document de tes scores&nbsp;!
                    </p>
                </div>
            </div>

            <div class="container">
                <div class="block100">
                    <ul class="justify-content">
                        <li class="results">
                            <router-link to="/dream" class="btn" aria-label="Bouton se rendre à la catégorie : Mes rêves">
                                {{ this.requestQ.dream.label }}
                            </router-link>
                            <p>
                                {{ this.count(dreamv) }}
                            </p>
                            <div v-if="dreamv < 75">
                                <p>
                                    Qui pourrait t’aider à continuer à explorer tes rêves ? Un·e conseillèr·e d’orientation&nbsp;? Un·e
                                    ami·e&nbsp;? Tes parents&nbsp;?
                                </p>
                            </div>
                        </li>
                        <li class="results">
                            <router-link to="/selfdone" class="btn" aria-label="Bouton se rendre à la catégorie : Mon autonomie">
                                {{ this.requestQ.selfdone.label }}
                            </router-link>
                            <p>
                                {{ this.count(selfdonev) }}
                            </p>
                            <div v-if="selfdonev < 75">
                                <p>
                                    Pour aller plus loin sur les questions de ta vie quotidienne, tu peux demander conseil à une
                                    assistante sociale dans ton établissement scolaire ou à ta mairie.
                                </p>
                            </div>
                        </li>
                        <li class="results">
                            <router-link to="/social" class="btn" aria-label="Bouton se rendre à la catégorie : Ma vie sociale">
                                {{ this.requestQ.social.label }}
                            </router-link>
                            <p>
                                {{ this.count(socialv) }}
                            </p>
                            <div v-if="socialv < 75">
                                <p>
                                    Pour aller plus loin sur les questions de ta vie sociale, tu peux demander conseil à
                                    l’infirmière scolaire de ton établissement ou à une MJC proche de chez toi.
                                </p>
                            </div>
                        </li>
                        <li class="results">
                            <router-link to="/school" class="btn" aria-label="Bouton se rendre à la catégorie : Ma scolarité">
                                {{ this.requestQ.school.label }}
                            </router-link>
                            <p>
                                {{ this.count(schoolv) }}
                            </p>
                            <div v-if="schoolv < 75">
                                <p>
                                    Pour aller plus loin sur les questions de ta scolarité, tu peux demander conseil à ton
                                    professeur principal ou au CPE de ton établissement.
                                </p>
                            </div>
                        </li>
                        <li class="results">
                            <router-link to="/health" class="btn" aria-label="Bouton se rendre à la catégorie : Ma santé">
                                {{ this.requestQ.health.label }}
                            </router-link>
                            <p>
                                {{ this.count(healthv) }}
                            </p>
                            <div v-if="healthv < 75">
                                <p>
                                    Pour aller plus loin sur les questions de ta santé, tu peux demander conseil à ton médecin
                                    traitant.
                                </p>
                            </div>
                        </li>
                        <li v-if="this.user.age >= 16" class="results">
                            <router-link to="/right" class="btn" aria-label="Bouton se rendre à la catégorie : Mes droits">
                                {{ this.requestQ.right.label }}
                            </router-link>
                            <p>
                                {{ this.count(rightv) }}
                            </p>
                            <div v-if="rightv < 75">
                                <p>
                                    Pour aller plus loin sur les questions de tes droits, tu peux demander conseil à une
                                    assistante sociale dans ton établissement scolaire ou à ta mairie.
                                </p>
                            </div>
                        </li>
                        <li v-if="this.user.enterprise === true" class="results">
                            <router-link to="/work" class="btn" aria-label="Bouton se rendre à la catégorie : Ma vie professionnelle">
                                {{ this.requestQ.work.label }}
                            </router-link>
                            <p>
                                {{ this.count(workv) }}
                            </p>
                            <div v-if="workv < 75">
                                <p>
                                    Pour aller plus loin sur les questions de ton parcours pro&nbsp;:
                                    Si tu es scolarisé·e, tu peux demander conseil à ton professeur principal ou à un·e
                                    conseiller·e d’orientation;
                                    Si tu n'es pas scolarisé·e, tu peux demander conseil à la <a href="https://www.mission-locale.fr/" target="_blank">mission locale</a>.
                                </p>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="container">
                <div class="block100">
                    <ul class="justify-content">
                        <li>
                            <button role="button" aria-label="Bouton revenir à la page précédente"
                                    @click="this.$router.go(-1)">Retour
                            </button>
                        </li>
                        <li>
                            <button role="button" aria-label="Bouton télécharger mes réponses en PDF" @click="printDocument"
                                    class="btn">Télécharger .PDF
                            </button>
                        </li>
                        <li>
                            <router-link to="/reset" class="btn"
                                         aria-label="Bouton effacer toutes mes réponses et recommencer">Reset
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

    </div>

    <Pdfmaker></Pdfmaker>
</template>

<script>

    // ** PDFMAKE doc. : https://pdfmake.github.io/docs/0.1/getting-started/
    // ** HTML-TO-PDF doc. : https://github.com/Aymkdn/html-to-pdfmake

    import pdfMake from 'pdfmake';
    import pdfFonts from 'pdfmake/build/vfs_fonts';
    import htmlToPdfmake from 'html-to-pdfmake';
    import Pdfmaker from "./Pdfmaker";

    export default {
        name: "Results",
        data() {
            return this.score();
        },
        components: {
            Pdfmaker,
        },
        methods: {
            score: function () {
                let categories = this.requestQ;
                let scores = {};

                for (const [i] of Object.entries(categories)) {
                    let totalq = 0, totalr = 0;

                    categories[i].questions.forEach((q) => {
                        if (this.user.age >= q.age) {
                            totalq++;

                            if (typeof this.user.answers[i][0][q.id] !== "undefined") {
                                totalr += parseInt(this.user.answers[i][0][q.id]);
                            }
                        }
                    });

                    scores[i + "v"] = totalr !== 0 ? Math.round(totalr * 100 / (totalq * 3)) : 0;
                }

                return scores;
            },
            count: function (param) {
                if (param < 25) {
                    return "Je ne me sens pas du tout autonome"
                } else if (param >= 25 && param < 50) {
                    return "Je me sens un peu autonome"
                } else if (param >= 50 && param < 75) {
                    return "Je me sens assez autonome"
                } else {
                    return "Je me sens tout à fait autonome"
                }
            },
            printDocument: function () {
                // ** get table html
                const pdfTable = document.getElementById('divAsPdf');
                // ** html to pdf format
                let html = htmlToPdfmake(pdfTable.innerHTML);
                const documentDefinition = {
                    content: html,
                    styles: {
                        fontSize: 22,
                        bold: true,
                    }
                };
                pdfMake.vfs = pdfFonts.pdfMake.vfs;
                pdfMake.createPdf(documentDefinition).open();
            },
        }
    }

</script>

<style scoped>
    h1 {
        margin-top: 0;
    }

    #results {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        min-height: 100vh;
        background: #FDD0AD url("../assets/img/fulltown_background.svg") no-repeat bottom;
        background-size: contain;
    }

    #results .center-content .container:nth-child(2) ul {
        flex-direction: column;
    }

    #results .container:nth-child(2) ul > li {
        display: flex;
        flex-flow: column wrap;
        align-items: center;
        justify-content: center;
        flex-grow: 1;
        flex-basis: 0;
        text-align: center;
        width: 100%;
        padding: 10px;
    }

    .results {
        padding: 20px 0;
    }

    .results > p {
        padding: 24px 42px;
        background-color: var(--mc);
        color: white;
        border-radius: 25px;
        border: 4px solid #ac2c2c;
    }

    .results > div > p {
        padding: 24px 42px;
        background-color: var(--tc);
        color: black;
        border-radius: 25px;
        border: 4px solid #ac2c2c;
        margin-top: 0;
    }

    .results > div > p > a {
        color: var(--mc)
    }

    .results > div > p > a:hover {
        color: var(--sc)
    }

</style>