<template>

    <div id="welcome">
        <div class="center-content">
            <div class="container">
                <div class="block75">
                    <h1><img src="../assets/img/Kiftavie.svg" alt=""></h1>

                    <p>
                        Bien des choses vont changer dans ta vie quand tu vas devenir adulte. C'est une nouvelle
                        aventure, tu auras de nouveaux droits, tu devras aussi prendre de nouvelles décisions. Ce guide
                        va t'accompagner en explorant ces différents champs.
                    </p>

                    <ul>
                        <li>
                            <router-link to="/settings" class="btn" aria-label="J'ai compris">
                                J'ai compris
                            </router-link>
                        </li>
                    </ul>

                </div>
            </div>
        </div>
    </div>


</template>

<script>
    export default {
        name: "Home",
    }
</script>

<style scoped>
    h1 {
        margin-top: 0;
    }

    h1 > strong {
        color: #CA3433;
    }

    #welcome {
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 100vh;
        background-image: url("../assets/img/fulltown_background.svg");
        background-repeat: no-repeat;
        background-position: bottom;
        background-size: contain;
        background-color: #FDD0AD;
    }

    #welcome h1 img {
        width: clamp(250px, 30vw, 400px)
    }
</style>
