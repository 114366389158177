import App from './App.vue';
import * as Vue from 'vue';
import router from './router/router';
import User from './services/user';
import './assets/css/main.css';

const app = Vue.createApp(App);

/**
 * Construct default user object as global
 * @type {User}
 */
app.config.globalProperties.user = new User(
    "John doe",
    13,
    false,
    {
        dream: [[], ""],
        selfdone: [[], ""],
        social: [[], ""],
        school: [[], ""],
        health: [[], ""],
        right: [[], ""],
        work: [[], ""]
    }
);

/**
 * JSON from API REST receiving
 * @type {{categories: {dream: {name: string, questions: *[]}, social: {name: string}, school: {name: string}, work: {name: string}, right: {name: string}, health: {name: string}, selfdone: {name: string}, personnal: {name: string, questions: *[]}}}}
 */

app.config.globalProperties.requestQ = {};

fetch('https://qcm-api.bycrea.dev/qcm').then(response =>{
    return response.json();
}).then(data =>{
    app.config.globalProperties.requestQ = data;
    console.log(data);

    app.use(
        router,
    ).mount('#app')
}).catch(e => {
    alert("une erreur s'est produite, merci de réessayer plus tard...");
    console.error(e.message);
});

/**
 * Fill user object with existing localstorage (if exist)
 */
if (localStorage.getItem("qcm-user")) {
    let parsing = JSON.parse(localStorage.getItem("qcm-user"));
    app.config.globalProperties.user = new User(
        parsing._name,
        parsing._age,
        parsing._enterprise,
        parsing._answers
    );
}

